<template>
  <vue-draggable-resizable
    :z="99"
    :resizable="false"
    drag-handle=".drag-area"
    class="widget-container"
    v-click-outside="() => closeWidget(taskToEdit)"
    h="auto"
  >
    <div class="task-add-widget-header drag-area">
      <img width="104" height="11" src="@/assets/images/menu.svg" alt="" />
    </div>
    <template v-if="!loading">
      <div
        class="task-add-widget-content"
        :key="'widget-content-' + updateWidget"
      >
        <div class="task-add-widget-content-category">
          <TaskTypeSelection v-model="task.category" :type="'primary'" />
          <TaskCategorySelection v-model="task.type" :type="task.category" />
          <div v-if="task.category === 'open_house'" class="open-house-info">
            <img src="../../assets/images/infoMark20.svg" alt="" />
            <p class="content-small-medium">
              {{ $t("OpenHouseInformationNotExported") }}
            </p>
          </div>
        </div>
        <TaskTextInput
          v-if="widgetFields.title"
          v-model="task.title"
          :required="task.category === 'event'"
          :placeholder="
            task.category === 'open_house' ? $t('Description') : $t('Title')
          "
          :error="
            (task.category === 'task' || task.category === 'event') &&
            showErrors &&
            !isFieldValid('content')
          "
          :error-message="$t('FormErrors.FieldRequired')"
        />
        <div style="display: flex; width: 100%">
          <div style="width: 100%">
            <div
              style="
                border-bottom: 1px solid #e6e8ec;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 16px;
                padding-right: 16px;
              "
            >
              <template
                v-if="
                  task.category === 'resource' ||
                  task.category === 'event' ||
                  task.category === 'open_house' ||
                  (task.category === 'task' && !noDueDateTask)
                "
              >
                <div style="display: flex; gap: 24px">
                  <TaskDatePicker
                    @change="chooseNewDate"
                    @changeFullDayToggle="changeFullDayToggle"
                    :label="widgetFields.dueDate ? 'Field.dueDate' : 'Date'"
                    :full-day-task="fullDayTask"
                    :task-category="task.category"
                    :dates="task.dates"
                  />
                  <div
                    v-if="!fullDayTask"
                    class="task-add-widget-content-date-from-to"
                  >
                    <img
                      height="20"
                      width="20"
                      :src="require('@/assets/images/clock_24_24.svg')"
                      alt=""
                    />
                    <div
                      v-if="widgetFields.dateFrom"
                      class="task-add-widget-content-date-from-to-inputs"
                    >
                      <div class="colored-label label12">{{ $t("From") }}</div>
                      <input
                        class="content"
                        type="time"
                        step="600"
                        v-model="task.dates.startTime"
                        @blur="taskStartTimeChanged"
                      />
                    </div>
                    <div
                      v-if="!widgetFields.dueTime"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                      "
                      class="content-normal"
                    >
                      <img
                        height="20"
                        width="14"
                        :src="require('@/assets/images/arrow_text.svg')"
                        alt=""
                      />
                    </div>
                    <div
                      v-if="widgetFields.dateTo || widgetFields.dueTime"
                      class="task-add-widget-content-date-from-to-inputs"
                    >
                      <div class="colored-label label12">
                        {{ widgetFields.dateTo ? $t("To") : $t("DueTime") }}
                      </div>
                      <input
                        class="content"
                        type="time"
                        step="600"
                        v-model="task.dates.endTime"
                        @blur="taskEndTimeChanged"
                      />
                    </div>
                  </div>
                </div>
                <img
                  v-if="task.category === 'task'"
                  @click="noDueDateTask = true"
                  style="cursor: pointer"
                  width="20"
                  height="20"
                  :src="require('@/assets/images/close_24_24.svg')"
                  alt=""
                />
              </template>
              <div
                class="link-small"
                style="cursor: pointer"
                @click="noDueDateTask = false"
                v-if="task.category === 'task' && noDueDateTask"
              >
                {{ $t("AddDueDate") }}
              </div>
            </div>
            <div
              style="
                padding-top: 16px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding-right: 16px;
              "
            >
              <div
                class="task-add-widget-content-project"
                v-if="widgetFields.project"
              >
                <TaskLinkedToSearch
                  v-model="task.linkedProject.id"
                  :items="projectSearchItems"
                  label="Project"
                  icon-name="projects.svg"
                  :not-found-item-name="task.linkedProject.name"
                  :required="task.category === 'open_house'"
                />
                <div
                  v-if="
                    task.category === 'open_house' &&
                    showErrors &&
                    !isFieldValid('project')
                  "
                >
                  <p
                    style="color: #ff1e24; padding-bottom: 12px"
                    class="content-normal"
                  >
                    {{ $t("FormErrors.FieldRequired") }}
                  </p>
                </div>
              </div>
              <div
                class="task-add-widget-content-contact"
                v-if="widgetFields.contact"
              >
                <TaskLinkedToSearch
                  v-model="task.linkedContact.id"
                  :not-found-item-name="task.linkedContact.name"
                  :items="contactSearchItems"
                  label="Contact"
                  icon-name="clients.svg"
                  :modalType="'client'"
                />
              </div>
              <div
                class="task-add-widget-content-number-of-participants"
                v-if="widgetFields.numberOfParticipants"
              >
                <div style="display: flex; gap: 12px; cursor: pointer">
                  <img
                    @click="addOpenHouseParticipants"
                    height="20"
                    width="20"
                    :src="require('@/assets/images/work_management_24_24.svg')"
                    alt=""
                  />
                  <div
                    style="display: flex; align-items: center"
                    @click="addOpenHouseParticipants"
                    v-if="!addOpenHouseParticipantsOpen"
                  >
                    <p
                      v-if="!task.numberOfParticipants"
                      class="link-small"
                      style="font-size: 12px; line-height: 16px"
                    >
                      {{
                        "+ " +
                        $t("Add") +
                        " " +
                        $t("NumberOfParticipants").toLowerCase()
                      }}
                    </p>
                    <p v-else>
                      {{ task.numberOfParticipants }}
                    </p>
                  </div>
                  <div v-if="addOpenHouseParticipantsOpen">
                    <input
                      ref="numberOfParticipants"
                      type="number"
                      autocomplete="off"
                      maxlength="3"
                      v-model="task.numberOfParticipants"
                      @keydown.enter="$event.target.blur()"
                      @blur="addOpenHouseParticipantsOpen = false"
                      style="
                        outline: none;
                        height: 36px;
                        border: 1px solid #e6e8ec;
                        border-radius: 8px;
                        padding: 6px 8px;
                      "
                      class="content-normal"
                      :placeholder="$t('NumberOfParticipants')"
                    />
                  </div>
                </div>
              </div>
              <div class="task-add-widget-comment">
                <img
                  src="@/assets/images/chat-no-bubble.svg"
                  style="width: 20px; height: 20px"
                  alt=""
                />
                <div
                  @click="openEditComment"
                  style="display: flex; align-items: center"
                  v-if="!task.comment && !editComment"
                >
                  <p
                    class="link-small"
                    style="font-size: 12px; line-height: 16px"
                  >
                    +
                    {{
                      $t("addComment").charAt(0).toUpperCase() +
                      $t("addComment").slice(1)
                    }}
                  </p>
                </div>
                <textarea
                  style="white-space: pre-wrap"
                  id="textarea"
                  class="task-textarea content-normal"
                  rows="5"
                  v-if="editComment"
                  ref="commentbox"
                  v-model="task.comment"
                  @focusout="closeEditComment"
                />
                <div
                  v-if="task.comment && !editComment"
                  @click="openEditComment"
                  style="display: flex; flex-direction: column"
                >
                  <div class="task-comment-header">
                    <p>{{ $t("Comment") }}</p>
                  </div>
                  <div
                    class="content-normal"
                    style="
                      white-space: pre-wrap;
                      width: 370px;
                      overflow-wrap: break-word;
                    "
                  >
                    {{ task.comment }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="widgetFields.resource"
            style="min-width: 207px; border-left: 1px solid #e6e8ec; display: flex; flex-direction: column; gap: 16px;"
          >
          <div style="              
            display: flex;
            justify-content: center; 
            width: 100%;"
          >
            <div
              style="
              display: flex;
              width: 176px;
              border: 1px solid #e6e8ec;
              border-radius: 8px;
              "
            >
              <TaskLinkedToSearch
                v-model="task.linkedUser.id"
                :not-found-item-name="task.linkedUser.name"
                :items="colleagueSearchItems"
                :custom-placeholder="$t('AddUser')"
                :small-search="true"
                @change="handleSelectedItem"
                class="button-small"
                :input-width="'176px'"
              />          
              <BaseButton
                @itemSelected="resourceSelected"
                :items="resources"
                description-key="description"
                icon-key="icon"
                display-key="name"
                display-value="_id"
                :empty-dropdown-text="$t('ResourceNotAddedToAddContactAdmin')"
                :label="$t('Resource').toLowerCase()"
                :size="'small'"
                :border="'0px solid #e6e8ec;'"
                :custom-style="'border-radius: 0 8px 8px 0; padding: 4px 6px; border: none; border-left: 1px solid #e6e8ec;'"
              />
            </div>
          </div>
            <div
              style="
                display: flex;
                height: 200px;
                overflow-y: auto;
                flex-direction: column;
                gap: 16px;
                align-items: center;
              "
            >
   
              <div style="display: flex; flex-direction: column; gap: 4px">
                <div
                  v-for="user of task.linkedUsers"
                  :key="user._id"
                  :class="user.icon ? 'already-booked-resource' : 'free-resource'"
                  style="display: flex; flex-wrap: nowrap; gap: 8px; justify-content: space-between; border-radius: 4px; width: 176px; padding: 4px 8px;"
                >
                  <div style="display: flex; flex-direction: column; gap: 2px">
                    <div style="display: flex; gap: 4px;">
                    <img
                    src="@/assets/images/users_16_16.svg"
                    alt=""
                  />
                    <div class="content-small">{{ user.name }}</div>
                  </div>
                  </div>
                  <img
                    @click="removeSelectedUser(user)"
                    :src="require('@/assets/images/close.svg')"
                    alt="remove resource"
                    height="16"
                    width="16"
                    style="cursor: pointer"
                  />
                </div>
              </div>
              <div
                style="display: flex; flex-direction: column; gap: 4px"
                :key="resourceBookingUpdater + '-resources'"
              >
              <div
                style="
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 8px;
                  justify-content: space-between;
                  border-radius: 4px;
                  width: 176px;
                  padding: 4px 8px;
                "
                v-for="resource of task.resources"
                :key="resource._id"
                :class="
                  resource.icon ? 'already-booked-resource' : 'free-resource'
                "
              >
                <div style="display: flex; flex-direction: column; gap: 2px">
                  <div style="display: flex; gap: 4px;">
                    <img
                      :src="require('@/assets/images/pencil_20_20.svg')"
                      height="16"
                      width="16"
                      alt="resource image" 
                    />
                    <div class="content-small">{{ resource.name }}</div>
                  </div>
                  <div style="color: #75787a" class="font-11-regular">
                    {{ resource.description }}
                  </div>
                </div>
                <img
                  style="cursor: pointer"
                  height="16"
                  width="16"
                  @click="removeSelectedResource(resource)"
                  :src="require('@/assets/images/close.svg')"
                  alt="remove resource"
                />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="task-add-widget-footer"
        :key="'widget-footer-' + updateWidget"
      >
        <div class="task-add-widget-footer-content">
          <div class="task-add-widget-footer-content-section">
            <TaskLinkedToSearch
              :disable-clear="true"
              v-model="task.assignedTo.userId"
              :items="ownerItems"
              label="Owner"
              :not-found-item-name="task.assignedTo.name"
              icon-name="users.svg"
            />
          </div>
          <div
            class="task-add-widget-footer-content-private-entry-toggle"
            v-if="widgetFields.privateEntry"
            @click="changePrivateEntryToggle"
          >
            <img
              style="cursor: pointer"
              :src="
                privateEntryTask
                  ? require('../../assets/images/toggle-active.svg')
                  : require('../../assets/images/toggle-inactive.svg')
              "
              alt=""
            />
            <p class="content-normal">
              {{ $t("Private") + " " + $t("Entry").toLowerCase() }}
            </p>
          </div>
        </div>
        <div class="task-add-widget-footer-buttons">
          <BaseButton
            @click="closeWidget(taskToEdit, true)"
            size="large"
            :label="$t('Cancel')"
          />
          <BaseButton
            @click="submitTask"
            size="large"
            :label="$t('Save')"
            :primary="true"
          />
        </div>
      </div>
    </template>
    <div v-if="loading" class="task-add-widget-loading-container">
      <div class="loader"></div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import BaseButton from "@/components/common/BaseButton";
import TaskDatePicker from "@/components/CalendarView/TaskDatePicker";
import axios from "axios";
import TaskLinkedToSearch from "@/components/CalendarView/TaskLinkedToSearch";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import TaskTypeSelection from "@/components/CalendarView/TaskTypeSelection";
import TaskCategorySelection from "@/components/CalendarView/TaskCategorySelection";
import TaskTextInput from "@/components/CalendarView/TaskTextInput";
import taskTypes from "@/components/CalendarView/taskTypes";

export default {
  name: "TaskAddWidget",
  components: {
    TaskTextInput,
    TaskCategorySelection,
    TaskTypeSelection,
    TaskLinkedToSearch,
    TaskDatePicker,
    BaseButton,
    VueDraggableResizable,
  },
  props: [
    "resource",
    "taskToEdit",
    "focusField",
    "newTaskTime",
    "linkedObject",
    "newCreatedContact",
    "contactModalOpen",
  ],
  data() {
    return {
      resourceBookingUpdater: 0,
      alreadyBookedResourceIds: [],
      resources: [],
      submitting: false,
      editComment: false,
      widgetFields: {
        category: false,
        title: false,
        type: false,
        icon: false,
        date: false,
        dateFrom: false,
        dateTo: false,
        dueDate: false,
        dueTime: false,
        fullDay: false,
        project: false,
        contact: false,
        resource: false,
        numberOfParticipants: false,
        privateEntry: false,
      },
      task: {
        category: "",
        comment: "",
        title: "",
        icon: "task",
        type: "generic",
        numberOfParticipants: null,
        dates: {
          startTime: "",
          endTime: "",
          startDate: "",
          endDate: "",
        },
        linkedProject: {
          id: null,
          name: null,
        },
        linkedContact: {
          id: null,
          name: null,
        },
        linkedUser: {
          id: null,
          name: null,
          email: null
        },
        linkedProperty: {
          id: null,
          name: null,
        },
        linkedDevelopment: {
          id: null,
          name: null,
        },
        linkedUnit: {
          id: null,
          name: null,
        },
        assignedTo: {
          userId: null,
          name: null,
        },
        resources: [],
        userTimezone: "",
        linkedUsers: []
      },
      fullDayTask: false,
      noDueDateTask: false,
      privateEntryTask: false,
      addOpenHouseParticipantsOpen: false,
      projectSearchItems: [],
      contactSearchItems: [],
      contactSearchCopyItems: [],
      colleagueSearchItems: [],
      ownerSearchItems: [],
      eventWidgetFieldKeys: [
        "category",
        "title",
        "type",
        "date",
        "dateFrom",
        "dateTo",
        "fullDay",
        "project",
        "contact",
        "privateEntry",
        "resource",
      ],
      taskWidgetFieldKeys: [
        "category",
        "title",
        "icon",
        "type",
        "dueDate",
        "dueTime",
        "fullDay",
        "project",
        "contact",
        "privateEntry",
      ],
      openHouseWidgetFieldKeys: [
        "category",
        "numberOfParticipants",
        "date",
        "dateFrom",
        "dateTo",
        "fullDay",
        "project",
        "contact",
        "title",
        "resource",
      ],
      updateWidget: 0,
      loading: false,
      showErrors: false,
      fieldToFocus: null,
      taskTitleTextAreaReload: 0,
      taskTitleTextAreaStyle: {},
      newContactSelected: false,
    };
  },
  computed: {
    ...mapGetters(["user", "users", "sidePanelContactAdd", "newAddedContact"]),
  },
  async created() {
    await this.initialize();
  },
  mounted() {
    if (this.focusField) {
      this.fieldToFocus = this.focusField;
      this.$emit("clearFocusField");
    }
  },
  watch: {
    "task.category": function (value) {
      if (!this.loading) {
        this.chooseCategory(value);
      }
    },
    "task.type": function (value) {
      if (this.task.category === "task") {
        this.chooseTaskIcon(value);
      }
    },
    newAddedContact() {
      if (this.newAddedContact) {
        this.addNewContactToList(this.newAddedContact);
      } else {
        this.newContactSelected = false;
      }
    },
    "task.assignedTo.userId": function () {
      const user = this.users.find(
        (user) => user._id === this.task.assignedTo.userId
      );
      if (user) {
        this.task.assignedTo.name = user.name;
      }
    },
    'task.linkedUser': {
      handler(newValue) {
        if (!newValue) {
          this.$set(this.task, 'linkedUser', {
            id: null,
            name: null,
            email: null
          });
        }
      },
      deep: true,
      immediate: true
    },
    loading(loading) {
      if (!loading) {
        if (this.fieldToFocus) {
          this.focusOnField(this.fieldToFocus);
        }
      }
    },
    taskTitleTextAreaReload() {
      if (this.task.category === "event" || this.task.category === "task") {
        this.fitTitleTextAreaContent();
      }
    },
  },
  methods: {
    ...mapActions(["getUsers"]),
    async taskStartTimeChanged() {
      if (this.task.category === "task") return;
      const newDate = moment(this.task.dates.startDate);
      const splitTime = this.task.dates.startTime.split(":");
      newDate.set({ h: parseInt(splitTime[0]), m: parseInt(splitTime[1]) });
      this.task.dates.startDate = newDate.format();
      if (
        moment(this.task.dates.endDate).isSameOrBefore(
          moment(this.task.dates.startDate)
        )
      ) {
        this.task.dates.endDate = moment(this.task.dates.startDate)
          .add(1, "hours")
          .format();
        this.task.dates.endTime = moment(this.task.dates.endDate).format(
          "HH:mm"
        );
      }
      await this.checkIfResourcesInBookedFrame({
        startDate: this.task.dates.startDate,
        endDate: this.task.dates.endDate,
      });
    },
    async taskEndTimeChanged() {
      if (this.task.category === "task") return;
      const newDate = moment(this.task.dates.endDate);
      const splitTime = this.task.dates.endTime.split(":");
      newDate.set({ h: parseInt(splitTime[0]), m: parseInt(splitTime[1]) });
      this.task.dates.endDate = newDate.format();
      await this.checkIfResourcesInBookedFrame({
        startDate: this.task.dates.startDate,
        endDate: this.task.dates.endDate,
      });
    },
    async initialize() {
      this.loading = true;
      await this.getSharedResources();
      if (!this.users) {
        await this.getUsers();
      }
      if (this.resource && !this.taskToEdit) {
        this.chooseCategory("resource", true);
        await this.setupColleagues();
      } else {
        if (this.taskToEdit) {
          this.chooseCategory(this.taskToEdit.category, true);
        } else {
          this.chooseCategory("event", true);
        }
        await Promise.all([
          this.setupProjects(),
          this.setupContacts(),
        ]);
        await this.setupColleagues()
        await this.setupOwner()
      }
      if (this.taskToEdit) {
        this.fillInEditTaskFields();
      }
      if (this.newTaskTime) {
        this.task.dates = this.newTaskTime;
        await this.checkIfResourcesInBookedFrame(this.task.dates);
      }
      this.handleLinkedObject();
      this.loading = false;
      this.taskTitleTextAreaReload++;
    },
    addButtonClicked() {
      this.$emit("addButtonClicked");
    },
    async getSharedResources() {
      let dontIncludeResourceIds = [];
      if (this.taskToEdit && this.taskToEdit.resources) {
        dontIncludeResourceIds = this.taskToEdit.resources.map(
          (resource) => resource._id
        );
      }

      await axios.get("/api/admin/get-shared-resources/1").then((response) => {
        if (
          response &&
          response.status &&
          response.status === 200 &&
          response.data
        ) {
          this.resources = response.data.filter(
            (resource) => !dontIncludeResourceIds.includes(resource._id)
          );
        }
      });
      this.resources.sort((a, b) => a.name.localeCompare(b.name));
      await this.checkIfResourcesInBookedFrame();
    },
    addNewContactToList(newContact) {
      this.contactSearchItems.push(newContact);
      this.contactSearchCopyItems.push(newContact);
      this.contactSearchItems.sort((a, b) => a.name.localeCompare(b.name));
      this.task.linkedContact.id = newContact.id;
      this.task.linkedContact.name = newContact.name;
      this.newContactSelected = true;
    },
    handleLinkedObject() {
      if (this.linkedObject) {
        switch (this.linkedObject.name) {
          case "project":
            if (
              this.projectSearchItems.find(
                (item) => item.id === this.linkedObject.id
              )
            ) {
              this.task.linkedProject = this.projectSearchItems.find(
                (item) => item.id === this.linkedObject.id
              );
            } else {
              this.projectSearchItems.push({
                id: this.linkedObject.id,
                name: this.linkedObject.itemName,
              });
              this.task.linkedProject = {
                id: this.linkedObject.id,
                name: this.linkedObject.itemName,
              };
            }
            break;
          case "contact":
            this.task.linkedContact = this.contactSearchItems.find(
              (item) => item.id === this.linkedObject.id
            );
            break;
          case "property":
            this.task.linkedProperty = {
              id: this.linkedObject.id,
              name: this.linkedObject.itemName,
            };
            break;
          case "unit":
            this.task.linkedUnit = {
              id: this.linkedObject.id,
              name: this.linkedObject.itemName,
            };
            break;
          case "development":
            this.task.linkedDevelopment = {
              id: this.linkedObject.id,
              name: this.linkedObject.itemName,
            };
            break;
        }
      }
    },
    focusOnField(field) {
      if (this.fieldToFocus) {
        this.fieldToFocus = null;
      }
      if (field === "participants") {
        this.addOpenHouseParticipants();
      }
    },
    fitTitleTextAreaContent() {
      this.$nextTick(() => {
        this.$refs.titleTextarea.style.height = "";
        this.$refs.titleTextarea.style.overflowY = "hidden";
        this.$refs.titleTextarea.style.height =
          this.$refs.titleTextarea.scrollHeight + "px";
      });
    },
    chooseCategory(category, firstRun = false) {
      this.showErrors = false;
      if (this.task.type === "open_house") {
        this.task.type = "generic";
      }
      this.task.category = category;
      this.loading = true;
      switch (category) {
        case "event":
          Object.keys(this.widgetFields).forEach((key) => {
            this.widgetFields[key] = this.eventWidgetFieldKeys.includes(key);
          });
          this.task.type = "generic";
          break;
        case "task":
          Object.keys(this.widgetFields).forEach((key) => {
            this.widgetFields[key] = this.taskWidgetFieldKeys.includes(key);
          });
          this.task.type = "call";
          this.task.resources = [];
          this.getSharedResources();
          break;
        case "open_house":
          Object.keys(this.widgetFields).forEach((key) => {
            this.widgetFields[key] =
              this.openHouseWidgetFieldKeys.includes(key);
          });
          break;
        case "resource":
          Object.keys(this.widgetFields).forEach((key) => {
            this.widgetFields[key] = this.resourceWidgetFieldKeys.includes(key);
          });
          break;
      }
      if (!firstRun) {
        this.loading = false;
        this.taskTitleTextAreaReload++;
      }
      this.updateWidget++;
    },
    async checkIfResourcesInBookedFrame(inputDates) {
      let bookedIdsForTimeframe = [];

      const taskId = this.taskToEdit ? this.taskToEdit._id : null;

      if (inputDates) {
        const dates = {
          startDate: moment(inputDates.startDate).format(),
          endDate: moment(inputDates.endDate).format(),
        };

        const alreadyBookedResources = await axios.post(
          "/api/tasks/resources/",
          { dates: dates, taskId: taskId }
        );
        if (alreadyBookedResources && alreadyBookedResources.data) {
          bookedIdsForTimeframe = alreadyBookedResources.data;
        }
      } else if (this.taskToEdit) {
        const dates = {
          startDate: this.taskToEdit.dates.startDate,
          endDate: this.taskToEdit.dates.endDate,
        };
        const alreadyBookedResources = await axios.post(
          "/api/tasks/resources/",
          { dates: dates, taskId: taskId }
        );
        if (alreadyBookedResources && alreadyBookedResources.data) {
          bookedIdsForTimeframe = alreadyBookedResources.data;
        }
      } else if (this.newTaskTime) {
        const alreadyBookedResources = await axios.post(
          "/api/tasks/resources/",
          { dates: this.newTaskTime }
        );
        if (alreadyBookedResources && alreadyBookedResources.data) {
          bookedIdsForTimeframe = alreadyBookedResources.data;
        }
      }
      this.alreadyBookedResourceIds = bookedIdsForTimeframe;

      for (const resource of this.resources) {
        if (bookedIdsForTimeframe.includes(resource._id)) {
          resource.icon = "alert.svg";
        } else {
          delete resource.icon;
        }
      }

      for (const resource of this.task.resources) {
        if (bookedIdsForTimeframe.includes(resource._id)) {
          resource.icon = "alert.svg";
        } else {
          delete resource.icon;
        }
      }

      this.resourceBookingUpdater++;
    },
    fillInEditTaskFields() {
      let taskType;
      if (this.taskToEdit.category === "task") {
        const taskTypes = ["call", "email", "invoice", "task"];
        taskType = taskTypes.includes(this.taskToEdit.type)
          ? this.taskToEdit.type
          : this.taskToEdit.icon;
      } else {
        taskType = this.taskToEdit.type ? this.taskToEdit.type : "generic";
        if (this.taskToEdit.type === "client_visit") {
          taskType = "showing_the_object";
        }
      }

      this.task = {
        category: this.taskToEdit.category ? this.taskToEdit.category : "",
        title: this.taskToEdit.content,
        comment: this.taskToEdit.comment,
        icon: this.taskToEdit.icon ? this.taskToEdit.icon : "task",
        type: taskType,
        dates: this.taskToEdit.dates,
        linkedProject: this.taskToEdit.linkedProject
          ? this.taskToEdit.linkedProject
          : {
              id: null,
              name: null,
            },
        numberOfParticipants: this.taskToEdit.numberOfParticipants
          ? this.taskToEdit.numberOfParticipants
          : null,
        linkedContact: this.taskToEdit.linkedContact
          ? this.taskToEdit.linkedContact
          : {
              id: null,
              name: null,
            },
        assignedTo: this.taskToEdit.assignedTo
          ? this.taskToEdit.assignedTo
          : {
              userId: null,
              name: null,
            },
        linkedUsers: this.taskToEdit.linkedUsers
      };
      if (this.taskToEdit.isPrivate) {
        this.privateEntryTask = true;
      }
      const taskToEditDates = this.taskToEdit.dates;
      if (
        !taskToEditDates.startTime &&
        !taskToEditDates.startDate &&
        !taskToEditDates.endDate &&
        !taskToEditDates.endTime
      ) {
        this.noDueDateTask = true;
        return;
      }
      if (taskToEditDates.endDate && !taskToEditDates.endTime) {
        this.fullDayTask = true;
      }
      if (this.taskToEdit.resources) {
        this.task.resources = this.taskToEdit.resources;
      }
    },
    chooseTaskIcon(iconName) {
      this.task.icon = iconName;
    },
    async chooseNewDate(newDate) {
      this.task.dates.startDate = newDate;
      this.task.dates.endDate = newDate;
      await this.checkIfResourcesInBookedFrame(this.mapDatesToNewTask());
      this.resourceBookingUpdater++;
    },
    async changeFullDayToggle(newBooleanValue) {
      this.fullDayTask = newBooleanValue;
      await this.checkIfResourcesInBookedFrame(this.mapDatesToNewTask());
      this.resourceBookingUpdater++;
    },
    changePrivateEntryToggle() {
      this.privateEntryTask = !this.privateEntryTask;
    },
    async setupProjects() {
      await axios.get("/api/projects/undone/names").then((projectResponse) => {
        if (projectResponse && projectResponse.status === 200) {
          const projects = projectResponse.data;
          projects.deals.forEach((project) => {
            let formattedProject = {
              id: project._id,
              name: project.name,
              createdAt: project.createdAt,
            };
            this.projectSearchItems.push(formattedProject);
          });
        } else {
          this.$emit("getTasks");
        }
      });
      this.projectSearchItems.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    async setupContacts() {
      const contactsResponse = await axios.get("/api/contacts/names");
      const contacts = contactsResponse.data;
      contacts.customers.forEach((contact) => {
        let formattedContact = {
          id: contact.customerId,
          name: contact.customerName,
          organization: contact.organization,
          contactIsPartner: contact.contactIsPartner,
          phoneNumber: contact.phoneNumber,
        };
        this.contactSearchItems.push(formattedContact);
      });
      this.contactSearchItems.sort((a, b) => a.name.localeCompare(b.name));
      this.contactSearchCopyItems = JSON.parse(
        JSON.stringify(this.contactSearchItems)
      );
    },
    async setupColleagues() {
      this.users.forEach((user) => {
        if (user._id === this.user._id) {
          return;
        }
        if (user.userStatus === "Active") {
          const formattedUser = {
            id: user._id,
            name: user.name,
          };
          this.colleagueSearchItems.push(formattedUser);
        }
      });
      this.colleagueSearchItems.sort((a, b) => a.name.localeCompare(b.name));
    },
    async setupOwner() {
      this.ownerItems = [ 
        {
          id: this.user._id,
          name: this.user.name,
        },
        ...this.colleagueSearchItems
      ];
      if (!this.taskToEdit) {
        this.task.assignedTo.userId = this.user._id;
        this.task.assignedTo.name = this.user.name;
      }
    },
    closeWidget(updatedTask, cancel = false) {
      if (this.editComment) return;
      if (this.sidePanelContactAdd) return;

      if (cancel && updatedTask && updatedTask.resources) {
        updatedTask.resources = updatedTask.resources.filter(
          (resource) => resource?.taskId
        );
      }

      this.$emit("closeWidget", updatedTask);
      this.$emit("getTasks");
    },
    checkForFieldErrors() {
      if (this.task.category === "open_house") {
        if (!this.task.linkedProject.id) {
          this.showErrors = true;
          this.submitting = false;
          return true;
        }
      }
      if (this.task.category === "event") {
        if (this.isWhitespaceOrEmpty(this.task.title)) {
          this.showErrors = true;
          this.submitting = false;
          return true;
        }
      }
      return false;
    },
    async submitTask() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      if (this.taskToEdit) {
        await this.prepareEditTask();
      } else {
        await this.preparePostTask();
      }
    },
    async preparePostTask() {
      if (this.checkForFieldErrors()) {
        return;
      }
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const newTask = {};
      newTask.createdAt = moment().format();
      newTask.creator = {
        userId: this.user._id,
        name: this.user.name,
      };
      newTask.assignedTo = {
        userId: this.task.assignedTo.userId,
        name: this.users.find(
          (user) => user._id === this.task.assignedTo.userId
        ).name,
      };
      newTask.category = this.task.category;
      newTask.type = this.task.type;
      newTask.linkedProject = this.mapProjectToNewTask();
      newTask.linkedContact = this.mapContactToNewTask();
      newTask.linkedProperty = this.task.linkedProperty;
      newTask.linkedDevelopment = this.task.linkedDevelopment;
      newTask.linkedUnit = this.task.linkedUnit;
      newTask.linkedUsers = this.task.linkedUsers;
      newTask.dates = this.mapDatesToNewTask();
      newTask.userTimezone = userTimezone;
      if (this.task.category === "open_house") {
        newTask.content = this.task.title;
      }
      if (this.task.category !== "open_house") {
        newTask.content = this.task.title;
        newTask.isPrivate = this.privateEntryTask;
        newTask.comment = this.task.comment;
      } else {
        newTask.content = "";
        newTask.type = "open_house";
        newTask.comment = this.task.comment;
        newTask.numberOfParticipants = this.task.numberOfParticipants;
        newTask.isPrivate = false;
      }
      if (this.task.category === "open_house") {
        newTask.content = this.task.title;
      }
      if (this.task.category === "task") {
        newTask.icon = this.task.type;
        if (!this.task.title) {
          newTask.content = this.$t(
            `${
              taskTypes.find((type) => type.value === this.task.type)
                .translation
            }`
          );
        }
      } else {
        newTask.icon = "";
      }
      await this.checkIfResourcesInBookedFrame(newTask.dates);
      newTask.resources = this.task.resources;

      let hasResourceBooked = false;
      for (let resource of newTask.resources) {
        if (resource.icon) {
          hasResourceBooked = true;
          break;
        }
      }
      if (hasResourceBooked) {
        this.toastError(this.$t("TaskIncludesAlreadyBookedResources"));
        this.submitting = false;
        return;
      }
      newTask.resources = newTask.resources.filter(
        (resource) => !resource.icon
      );

      if (this.resource) {
        newTask.resourceId = this.resource._id;
        newTask.content = this.resource.name;
        newTask.category = "resource";
        newTask.type = "resource";
      }
      await this.postTask(newTask);
    },
    async prepareEditTask() {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const updatedTask = JSON.parse(JSON.stringify(this.taskToEdit));
      updatedTask.category = this.task.category;
      updatedTask.userTimezone = userTimezone; 
      if (this.task.category === "task") {
        updatedTask.icon = this.task.icon;
      } else {
        updatedTask.icon = "";
      }

      if (updatedTask.category === "open_house") {
        updatedTask.type = "open_house";
        updatedTask.comment = this.task.comment;
        updatedTask.content = this.task.title;
        updatedTask.numberOfParticipants = this.task.numberOfParticipants;
        updatedTask.isPrivate = false;
      } else {
        updatedTask.type = this.task.type;
        updatedTask.comment = this.task.comment;
        updatedTask.content = this.task.title;
        updatedTask.isPrivate = this.privateEntryTask;
      }
      updatedTask.dates = this.mapDatesToNewTask();
      updatedTask.linkedProject = this.mapProjectToNewTask();
      updatedTask.linkedContact = this.mapContactToNewTask();
      updatedTask.linkedUsers = this.task.linkedUsers;
      await this.checkIfResourcesInBookedFrame(updatedTask.dates);
      updatedTask.resources = this.task.resources;

      let hasResourceBooked = false;
      for (let resource of updatedTask.resources) {
        if (resource.icon) {
          hasResourceBooked = true;
          break;
        }
      }
      if (hasResourceBooked) {
        this.toastError(this.$t("TaskIncludesAlreadyBookedResources"));
        this.submitting = false;
        return;
      }

      updatedTask.resources = updatedTask.resources.filter(
        (resource) => !resource.icon
      );

      updatedTask.assignedTo = {
        userId: this.task.assignedTo.userId,
        name: this.users.find(
          (user) => user._id === this.task.assignedTo.userId
        ).name,
      };
      if (this.task.category === "task") {
        updatedTask.icon = this.task.type;
        if (!this.task.title) {
          updatedTask.content = this.$t(
            `${
              taskTypes.find((type) => type.value === this.task.type)
                .translation
            }`
          );
        }
      } else {
        updatedTask.icon = "";
      }
      await this.updateTask(updatedTask);
    },
    async updateTask(updatedTask) {
      if (this.checkForFieldErrors()) {
        return;
      }
      try {
        if (this.user.microsoft) {
          const access = await this.getMsalAccessToken();
          if (access) {
            updatedTask.accessToken = access.accessToken;
          }
        }
        await axios
          .patch(`/api/task/${this.taskToEdit._id}`, updatedTask)
          .then((response) => {
            if (response.status && response.status === 200) {
              this.toastSuccess(
                this.$t("Task") + " " + this.$t("Updated").toLowerCase()
              );
              this.$emit("getTasks", updatedTask);
              this.closeWidget(updatedTask);
            } else {
              this.toastError(this.$t("ErrorUpdating"));
            }
          });
      } catch (e) {
        await this.logError("[Frontend] Event updated", e);
      }
    },
    async postTask(taskToPost) {
      try {
        if (this.user.microsoft) {
          const access = await this.getMsalAccessToken();
          if (access) {
            taskToPost.accessToken = access.accessToken;
          }
        }
        await axios.post("/api/task", taskToPost).then((response) => {
          if (response.status && response.status === 200) {
            this.toastSuccess(
              this.$t("Task") + " " + this.$t("Created").toLowerCase()
            );
            this.$emit("getTasks");
            this.closeWidget();
          } else {
            this.toastError(this.$t("ErrorCreating"));
          }
        });
      } catch (e) {
        await this.logError("[Frontend] Event created", e);
      }
    },
    mapProjectToNewTask() {
      if (!this.task.linkedProject.id) {
        return {
          id: null,
          name: null,
        };
      }
      return {
        id: this.task.linkedProject.id,
        name: this.projectSearchItems.find(
          (project) => project.id === this.task.linkedProject.id
        ).name,
      };
    },
    mapContactToNewTask() {
      if (!this.task.linkedContact.id) {
        return {
          id: null,
          name: null,
        };
      }
      return {
        id: this.task.linkedContact.id,
        name: this.contactSearchCopyItems.find(
          (contact) => contact.id === this.task.linkedContact.id
        ).name,
      };
    },
    mapDatesToNewTask() {
      if (
        this.task.category === "event" ||
        this.task.category === "open_house" ||
        this.task.category === "resource"
      ) {
        if (!this.task.dates.startDate && this.task.dates.endDate) {
          this.task.dates.startDate = this.task.dates.endDate;
        }
        if (this.task.dates.startTime && !this.task.dates.endTime) {
          this.task.dates.endTime = "23:59";
        }
        if (this.task.dates.endTime && !this.task.dates.startTime) {
          this.task.dates.startTime = "00:00";
          if (!this.task.dates.startDate) {
            this.task.dates.startDate = moment(this.task.dates.endDate).startOf(
              "day"
            );
          }
        }
      }
      if (
        (!this.task.dates.endDate &&
          !this.task.dates.startDate &&
          !this.task.dates.endTime &&
          !this.task.dates.startTime) ||
        (this.noDueDateTask && this.task.category === "task")
      ) {
        return {
          startTime: null,
          endTime: null,
          startDate: null,
          endDate: null,
        };
      }
      if (this.fullDayTask) {
        const startDate = moment(this.task.dates.startDate).startOf("day");
        const endDate = moment(this.task.dates.endDate).endOf("day");
        return {
          startTime: null,
          endTime: null,
          startDate: startDate.format(),
          endDate: endDate.format(),
        };
      }
      if (this.task.dates.endTime && this.task.dates.endDate) {
        const newDate = moment(this.task.dates.endDate);
        const splitTime = this.task.dates.endTime.split(":");
        newDate.set({ h: parseInt(splitTime[0]), m: parseInt(splitTime[1]) });
        this.task.dates.endDate = newDate.format();
      }
      if (this.task.dates.startTime && this.task.dates.startDate) {
        const newDate = moment(this.task.dates.startDate);
        const splitTime = this.task.dates.startTime.split(":");
        newDate.set({ h: parseInt(splitTime[0]), m: parseInt(splitTime[1]) });
        this.task.dates.startDate = newDate.format();
      }
      if (
        this.task.category === "event" ||
        this.task.category === "open_house" ||
        this.task.category === "resource"
      ) {
        if (
          moment(this.task.dates.endDate).isBefore(
            moment(this.task.dates.startDate)
          )
        ) {
          this.task.dates.endDate = moment(this.task.dates.startDate)
            .add(1, "hours")
            .format();
          this.task.dates.endTime = moment(this.task.dates.endDate).format(
            "HH:mm"
          );
        }
      }
      return {
        startTime: this.task.dates.startTime,
        endTime: this.task.dates.endTime,
        startDate: moment(this.task.dates.startDate).format(),
        endDate: moment(this.task.dates.endDate).format(),
      };
    },
    addOpenHouseParticipants() {
      this.addOpenHouseParticipantsOpen = true;
      this.$nextTick(() => {
        this.$refs.numberOfParticipants.focus();
      });
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "project":
          return !!this.task.linkedProject.id;
        case "content":
          return !this.isWhitespaceOrEmpty(this.task.title);
      }
    },
    isWhitespaceOrEmpty(value) {
      return value === "" || /^\s*$/.test(value);
    },
    openEditComment() {
      this.editComment = true;
      this.$nextTick(() => {
        this.$refs.commentbox.focus();
      });
    },
    closeEditComment() {
      const closeEditComment = setInterval(() => {
        this.editComment = false;
        clearInterval(closeEditComment);
      }, 70);
    },
    resourceSelected(resource) {
      this.task.resources.push(resource);
      this.resources = this.resources.filter(
        (responseResource) => responseResource._id !== resource._id
      );
    },
    removeSelectedResource(resource) {
      this.task.resources = this.task.resources.filter(
        (selectedResource) => resource._id !== selectedResource._id
      );
      this.resources.push(resource);
      this.resources.sort((a, b) => a.name.localeCompare(b.name));
    },
    removeSelectedUser(user) {
      const index = this.task.linkedUsers.indexOf(user);
      if (index > -1) {
        this.task.linkedUsers.splice(index, 1);
      }
    },
    handleSelectedItem(selectedId) {
      if (selectedId && !this.task.linkedUsers.some(user => user._id === selectedId)) {
        const selectedUser = this.users.find(user => user._id === selectedId);
        if (selectedUser) {
          this.task.linkedUsers.push({
            _id: selectedUser._id,
            name: selectedUser.name,
            email: selectedUser.email,
          });
          this.task.linkedUser.id = null;
          this.task.linkedUser.name = null;
          this.task.linkedUser.email = null;
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "src/global_variables";

.widget-container {
  position: fixed;
  background: white;
  border-radius: 8px;
  top: calc(50% - 250px);
  right: calc(50% - 272px);
  min-width: 696px;
  max-width: 696px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11),
    0 0 0 rgba(0, 0, 0, 0.1);
}

.task-add-widget-header {
  background-color: #f4f5f7;
  height: 35px;
  display: flex;
  border-bottom: 1px solid #e6e8ec;
  border-radius: 8px 8px 0 0;
  padding: 12px 16px;
}

.drag-area {
  cursor: grab;
}

.drag-area:active {
  cursor: grabbing;
}

.task-add-widget-content {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.task-add-widget-footer {
  border-top: 1px solid #e6e8ec;
  max-height: 72px;
  padding: 16px 24px;
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
}

.colored-label {
  color: #75787a;
}

.task-add-widget-footer-content {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.task-add-widget-footer-content-section {
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
  width: 100%;
}

.task-add-widget-footer-buttons {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}

.task-add-widget-loading-container {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-add-widget-content-category {
  height: 32px;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  align-items: center;
}

.task-add-widget-content-category-item-selected {
  background: #ff5c01;
  border-radius: 6px;
  cursor: initial !important;
}

.task-add-widget-content-category-item-selected-open-house {
  background: #7200e2;
  border-radius: 6px;
  cursor: initial !important;
}

.task-add-widget-content-category-item-selected-open-house p {
  color: white;
}

.task-add-widget-content-category-item-selected p {
  color: white;
}

.task-add-widget-content-category-item {
  padding: 8px 16px;
  cursor: pointer;
}

.category-bold {
  font-weight: bold;
}

.task-add-widget-content-title {
  width: 100%;
}

.task-add-widget-content-title textarea {
  width: 100%;
  font-size: 20px;
  height: 56px;
  resize: none;
  line-height: 24px;
  font-weight: 400;
  background-color: #fafafa;
  font-family: Inter, sans-serif;
  border-bottom: 1px solid black;
  padding: 16px 8px 15px 8px;
  outline: none;
}

.task-add-widget-content-title textarea:focus {
  border-bottom: 2px solid #ff5c01;
  padding-bottom: 14px;
}

.task-add-widget-content-icon {
  height: 36px;
  display: flex;
  flex-wrap: nowrap;
}

.task-add-widget-content-icon-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  border: 1px solid #e6e8ec;
  padding: 8px 12px;
  cursor: pointer;
  background: white;
}

.task-add-widget-content-icon-content:first-child {
  border-left: 1px solid #e6e8ec;
  border-radius: 8px 0 0 8px;
  border-right: none;
}

.task-add-widget-content-icon-content:last-child {
  border-right: 1px solid #e6e8ec;
  border-radius: 0 8px 8px 0;
  border-left: none;
}

.task-add-widget-content-icon-content-selected {
  cursor: initial !important;
  background: #f4f5f7 !important;
}

.task-add-widget-content-type {
  padding: 2px;
  gap: 12px;
  display: flex;
  flex-wrap: nowrap;
  z-index: 101;
}

.task-add-widget-content-number-of-participants {
  display: flex;
  gap: 12px;
  min-height: 20px;
}

.task-add-widget-content-date {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.task-add-widget-content-date-from-to {
  display: flex;
  gap: 12px;
  height: 36px;
}

.task-add-widget-content-date-from-to-inputs input {
  cursor: pointer;
}

.task-add-widget-content-date-from-to-inputs
  input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 38px;
  height: 12px;
  margin-top: -38px;
  margin-left: -2px;
  opacity: 0;
  cursor: pointer;
  background: green;
}

.task-add-widget-content-project,
.task-add-widget-content-contact {
  min-height: 20px;
}

.task-add-widget-footer-content-private-entry-toggle {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
}

.task-textarea#textarea {
  width: 100%;
  resize: none;

  p {
    font-size: 14px;
  }
}

.task-add-widget-comment {
  display: flex;
  flex-direction: row;
  gap: 12px;

  p {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}
.open-house-info {
  display: flex;
  align-items: center;
  margin-left: 8px;
  background: #f4f5f7;
  border-radius: 8px;
  padding: 4px 8px;
  height: 28px;
  gap: 8px;
}

.task-comment-header {
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #75787a;
  }
}

.already-booked-resource {
  border: 2px solid $red;
}

.free-resource {
  border: 2px solid $green;
}
</style>
